import React from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import GetStartedInput from "../GetStartedInput";
import styles from "./confirmModal.module.scss";
ReactModal.setAppElement('body');

const ConfirmModal = ({ isOpen = false, toggleOpen, customStyles, planDetails, isSignupForm, toggleForm }) => {
  const handleClose = () => toggleOpen(false)
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
      className={cx(styles.content, customStyles)}
    >
      <button onClick={handleClose} className={styles.closeBtn}>&times;</button>
      {!isSignupForm ? <div>
        <p><strong>Thank you!</strong></p>
        <p>Please use the confirmation link sent to your email. Activate your new Entracker Trial Plan account and start using it with your team!</p>
      </div> :
      <div>
        <p><strong>Start a free trial</strong></p>
        <p className={styles.description}>You've selected <b>{planDetails.plan.toUpperCase()}</b> plan,<br/>for up to {planDetails.quantityLabel}, {planDetails.intervalName.toLowerCase()}</p>
        <p className={styles.description}>Please provide your company email below.</p>
        <GetStartedInput toggleForm={toggleForm} planDetails={planDetails} />
      </div>}
    </ReactModal>
  )
}

export default ConfirmModal;
