import axios from 'axios';
import uuid4 from 'uuid/v4';
import urls from './root';

// const API_ROOT = 'http://127.0.0.1:3000/api/';//'https://app.entracker.com/api/';

export default ({ endpoint, method, body }) => {
  const fullUrl = urls.apiUrl + endpoint;
  const options = {
    method,
    url: fullUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    mode: 'cors',
  };

  // dodanie idempotency key
  if (['POST', 'PUT', 'PATCH'].includes((method || 'GET').toUpperCase())) {
    options.headers['Idempotency-Key'] = uuid4();
  }

  // przeslanie danych (z ew. zamiana na FormData)
  if (body) {
    Object.assign(options, { data: body });
  }

  return axios(options)
    .then((response) => response.data);
}
