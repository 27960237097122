import React from 'react';
import callApi from '../../utils/api';
import Button from '../Button';
import styles from "./getStartedInput.module.scss";

class GetStartedInput extends React.PureComponent {
  constructor() {
   super();
   this.defaultError = 'Enter correct email';
   this.state = {
     input: '',
     password: '',
     errorMsg: null,
     isLoading: false,
   };
  }

  handleSubmit = event => {
    const { planDetails } = this.props;
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    const emailVal = data.get('email');
    const passwordVal = data.get('password');
    if (!event.target.checkValidity() || !!passwordVal) {
      this.setState({ errorMsg: this.defaultError });
    } else {
      this.setState({ errorMsg: null, isLoading: true });
      callApi({
        endpoint: 'auth/signup',
        method: 'POST',
        body: {
          email: emailVal,
          plan: planDetails ? planDetails.plan : 'premium',
          interval: planDetails ? planDetails.interval : 'month',
          quantity: planDetails ? planDetails.quantity : 20,
        }
      })
      .then(response => {
        this.setState({ isLoading: false, input: '' });
        if (this.props.toggleForm) {
          this.props.toggleForm(true);
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error.response && error.response.data) {
          if (error.response.data.fieldErrors && error.response.data.fieldErrors[0]) {
            this.setState({ errorMsg: error.response.data.fieldErrors[0] });
          } else if (error.response.data.nonFieldErrors && error.response.data.nonFieldErrors[0]) {
            this.setState({ errorMsg: error.response.data.nonFieldErrors[0] });
          }
        } else {
          this.setState({ errorMsg: 'Error occured, please try again' });
        }
      });
    }
  }

  handleChangeEmail = e => {
    this.setState({ input: e.target.value });
    if (this.state.errorMsg) {
      this.setState({ errorMsg: null });
    }
  }

  handleChangePassword = e => {
    this.setState({ password: e.target.value });
  }

  render() {
    const { isLoading, input, password } = this.state;
     return (
       <>
         <form noValidate className={styles.form} onSubmit={this.handleSubmit}>
           <div className={styles.inputWrap}>
             <input
               className={styles.input}
               id="email"
               type="email"
               name="email"
               required
               value={input}
               placeholder="Enter your business email"
               onChange={this.handleChangeEmail}
             />
             {!!this.state.errorMsg && <p className={styles.error}>{this.state.errorMsg}</p>}
             <input
               className={styles.input2}
               id="password"
               type="password"
               name="password"
               required={false}
               value={password}
               placeholder="Enter password"
               onChange={this.handleChangePassword}
             />
           </div>
           <Button className={styles.button} isLoading={isLoading} type="submit" content="Get started for free!" />
         </form>
       </>
     );
  }
}

export default GetStartedInput;
