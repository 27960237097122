import { Link } from 'gatsby';
import React from 'react';
import { Watch } from 'scrollmonitor-react';
// import Menu from 'react-burger-menu/lib/menus/slide'
import cx from 'classnames';
import Button from "../Button";
import BurgerMenu from "./BurgerMenu";
import urls from '../../utils/root';
import logoColor from '../../images/logo-color.svg';
import logoWhite from '../../images/logo-white.svg';
import styles from "./header.module.scss";

class Header extends React.PureComponent {
  constructor() {
    super();
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  getListItem = (elem, mobileDisabled = false) => {
    if (mobileDisabled) {
      return <li className={cx(styles.desktopMenuItem, styles.mobileDisabled)}>{elem}</li>;
    }
    return <li className={styles.desktopMenuItem}>{elem}</li>;
  }

  getMenuMenuStyle = page => (
    cx(styles.desktopMenuLink, { [styles.isActive]: this.props.page === page })
  )

  render () {
    const { isInViewport } = this.props;
    const isInViewport2 = this.isComponentMounted ? isInViewport : true;
    const isNotInViewport2 = this.isComponentMounted && !isInViewport;
    return (
      <header className={styles.wrapper}>
        <div className={cx({
          [styles.outerContainer]: true,
          [styles.outerContainerScrolled]: isNotInViewport2,
        })}>
          <div className={styles.container}>
            <nav className={cx({
              [styles.innerContainer]: isInViewport2,
              [styles.innerContainerScrolled]: !isInViewport2,
              [styles.isAnimated]: true,
            })}>
              <Link to="/" className={styles.logoWrap}>
                {isInViewport2 ?
                  <img className={styles.logo} src={logoWhite} alt="Entracker" /> :
                  <img className={styles.logo} src={logoColor} alt="Entracker" />}
              </Link>
              <ul className={styles.desktopMenuWrap}>
                {this.getListItem(<Link className={this.getMenuMenuStyle('pricing')} to="/pricing/">Pricing</Link>)}
                {this.getListItem(<Link className={this.getMenuMenuStyle('resources')} to="/resources/">Resources</Link>)}
                {this.getListItem(<Link className={this.getMenuMenuStyle('security')} to="/security/">Security</Link>)}
                {this.getListItem(<Button to="/pricing/" color="green" roundedCorners content="Start for free" />)}
                {this.getListItem(<Button type="external-link" url={urls.url} color={isInViewport2 ? 'white' : 'blue'} roundedCorners content="Login" />)}
              </ul>
              <div className={styles.mobileMenuWrap}>
                <BurgerMenu isDark={!isInViewport2} />
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
}

export default Watch(Header);
