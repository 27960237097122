import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children, isSubpage, page, hasDocsLink = false }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header page={page} siteTitle={data.site.siteMetadata.title} />
        <div>
          {children}
          <Footer hasDocsLink={hasDocsLink} />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
