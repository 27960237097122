import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import cx from 'classnames';
import GetStartedInput from "../GetStartedInput";
import ConfirmModal from "../ConfirmModal";
import ObfuscatedEmail from "../ObfuscatedEmail";
import FooterLinkBox from '../HomepageContent/FooterLinkBox';
import logoColor from '../../images/logo-color.svg';
import styles from "./footer.module.scss";

const Footer = ({ hasDocsLink = false }) => {
  const [isOpen, toggleOpen] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query {
          footerBanner: file(relativePath: { eq: "footer-banner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <footer>
          <div className={cx(
            styles.container,
            { [styles.containerWithDocs]: hasDocsLink }
          )}>
            {hasDocsLink && <FooterLinkBox />}
            <section className={styles.innerContainer}>
              <div className={styles.topRow}>
                <div className={styles.topCol}>
                  <div className={styles.topCol1Inner}>
                    <h2 className={styles.heading}>Get started with Entracker!</h2>
                    <p>Check how our application can help your engineering and detailing teams with our 14-day free trial.</p>
                  </div>
                </div>
                <div className={styles.topCol}>
                  <GetStartedInput toggleForm={toggleOpen} />
                  <p className={styles.contactUs}>or contact our <ObfuscatedEmail email="support@entracker.com" label="support team" /></p>
                </div>
              </div>
            </section>
            {/* <Img className={styles.heroBanner} fluid={data.footerBanner.childImageSharp.fluid} /> */}
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.col1}>
              <Link to="/" className={styles.logoWrap}>
                <img className={styles.logo} src={logoColor} alt="Entracker" />
              </Link>
              <p>Project management for engineers</p>
            </div>
            <ul className={styles.col2}>
              <li><Link to="/cookies/">Cookie Policy</Link></li>
              <li><Link to="/terms/">Terms of Service</Link></li>
              <li><Link to="/privacy/">Privacy Policy</Link></li>
              <li><Link to="/resources/">Resources</Link></li>
            </ul>
          </div>
          <div className={styles.bottomLineWrap}>
            <p>© 2019 Entracker. All rights reserved.</p>
          </div>
          <ConfirmModal isOpen={isOpen} toggleOpen={toggleOpen} />
        </footer>
      )}
    />
  )
}

export default Footer
