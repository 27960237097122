import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import styles from "./footerLinkBox.module.scss";

const FooterLinkBox = () => (
  <div className={styles.linkOuterWrap}>
    <Fade>
      <Link
        to="/resources/"
        className={styles.link}
      >
        <div className={styles.linkInnerWrap}>
          <h3>Want to learn more? <span>&nbsp;&rarr;</span></h3>
          <p>Check our documentation and see in details how working with Entracker will make your project management and tracking drawing revisions easy.</p>
        </div>
      </Link>
    </Fade>
  </div>
)

export default FooterLinkBox;
