import { Link } from 'gatsby';
import React from 'react';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';
import BurgerMenuBtn from "./BurgerMenuBtn";
import urls from '../../../utils/root';
import Button from "../../Button";
import styles from "./burger-menu.module.scss";

class BurgerMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false,
      isDisplayed: false,
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleClickOutside() {
    if (this.state.isDisplayed) {
      this.toggleMenu();
    }
  }

  toggleMenu = () => {
    if (this.state.isDisplayed) {
      clearTimeout(this.timeout);
      this.setState({ isVisible: false });
      this.timeout = setTimeout(() => this.setState({ isDisplayed: false }), 400);
    } else if (!this.state.isDisplayed) {
      clearTimeout(this.timeout);
      this.setState({ isDisplayed: true });
      this.timeout = setTimeout(() => this.setState({ isVisible: true }), 100);
    }
  }

  render () {
    const { isDisplayed, isVisible } = this.state;
    return (
      <div className={styles.menuWrapper}>
        <BurgerMenuBtn isOpen={isVisible} toggleMenu={this.toggleMenu} isDark={this.props.isDark || isVisible} />
        <ul className={cx({
          [styles.popupMenu]: true,
          [styles.popupDisplayed]: isDisplayed,
          [styles.popupVisible]: isVisible,
        })}>
          <li className={styles.menuItem}>
            <Link className={styles.menuLink} to="/pricing/" onClick={this.toggleMenu}>Pricing</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.menuLink} to="/resources/" onClick={this.toggleMenu}>Resources</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.menuLink} to="/security/" onClick={this.toggleMenu}>Security</Link>
          </li>
          <li className={styles.menuItem2}>
            <Button
              to="/pricing/"
              onClick={this.toggleMenu}
              color="green"
              roundedCorners
              content="Start for free"
              className={styles.dropdownMenuButton}
            />
          </li>
          <li className={styles.menuItem2}>
            <Button
              type="external-link"
              url={urls.url}
              color="blue"
              roundedCorners
              content="Login"
              className={styles.dropdownMenuButton}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default onClickOutside(BurgerMenu);
